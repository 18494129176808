import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Helmet } from "react-helmet"
import { useI18next } from "gatsby-plugin-react-i18next"
import { globalHistory as history } from "@reach/router"
import favicon16x16 from "../images/favicon-16x16.png"
import favicon32x32 from "../images/favicon-32x32.png"
import favicon64x64 from "../images/favicon-64x64.png"

const removeBackSlashEnd = path => {
  return path.endsWith("/") ? path.slice(0, path.length - 1) : path
}

const removeBackSlashStart = path => {
  return path.startsWith("/") ? path.slice(1, path.length) : path
}

const httpsTohttp = path => {
  return path.replace(/^https:\/\//i, "http://")
}

const getImageType = path => {
  let regexp = /jpeg|png?/gi
  return path.match(regexp)
}

const Seo = props => {
  const { location } = history

  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title_da
            title_en
            description_da
            description_en
            siteUrl
            author
            right_da
            right_en
            keywords_en
            keywords_da
          }
        }
      }
    `
  )

  const siteMetadata = data.site.siteMetadata

  const { languages, language, originalPath, defaultLanguage } = useI18next()

  const siteUrl = removeBackSlashEnd(siteMetadata.siteUrl)
  const NoSecuresiteUrl = httpsTohttp(siteUrl)

  const children = props.childen
  const title =
    language === `da`
      ? props.title || `${siteMetadata.title_da}`
      : props.title || `${siteMetadata.title_en}`
  const description =
    language === `da`
      ? props.description || `${siteMetadata.description_da}`
      : props.description || `${siteMetadata.description_en}`
  const canonical = props.canonical || `${siteUrl}${location.pathname}`
  const contentType = props.contentType || "article"
  const published = props.published || new Date().toISOString()
  const updated = new Date().toISOString()
  const category = props.category
  const tags = props.tags
  const twitter = props.twitter
  const readingTime = `${props.readingTime} min.`
  const author = props.author || `${siteMetadata.author}`
  const standardImage = `${siteUrl}/SoMe/open_graph_ejaas_logo.png`
  const rights =
    language === `da` ? `${siteMetadata.right_da}` : `${siteMetadata.right_en}`
  const keywords =
    language === `da`
      ? props.keywords || `${siteMetadata.keywords_da}`
      : props.keywords || `${siteMetadata.keywords_en}`
  const imageType = props.image
    ? `image/${getImageType(props.image)}`
    : `image/${getImageType(standardImage)}`

  const imageWidth = "1200"
  const imageHeight = "630"

  const createUrlWithLang = lng => {
    const url = `${siteUrl}${
      lng === defaultLanguage ? "" : `/${lng}`
    }${originalPath}`
    return url.endsWith("/") ? url : `${url}/`
  }

  const canonicalUrl = `${siteUrl}${location.pathname}` //this CANNOT be overwritten from SEO props!!

  return (
    <Helmet
      htmlAttributes={{ lang: language }}
      title={`${siteMetadata.author} | ${title}`}
    >
      {children}
      <link rel="icon" type="image/png" sizes="16x16" href={favicon16x16} />
      <link rel="icon" type="image/png" sizes="32x32" href={favicon32x32} />
      <link rel="icon" type="image/png" sizes="64x64" href={favicon64x64} />
      <link
        rel="alternate"
        href={createUrlWithLang(defaultLanguage)}
        hrefLang="x-default"
      />
      <link rel="canonical" href={canonical} />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta name="rights" content={rights}></meta>
      <meta name="keywords" content={keywords}></meta>
      <meta name="msvalidate.01" content="08370FD4F682385C7448A156056A7FCF" />
      <meta
        name="google-site-verification"
        content="00yz3BPVfD5_WLx5YcXQ_GwkFBprJSBX9Qkl0Rs2nAg"
      />
      <meta name="description" content={description} />
      <meta name="twitter:card" content="summary_large_image" />
      {/* <meta name="twitter:site" content="" /> */}
      <meta
        name="twitter:title"
        content={`${siteMetadata.author} | ${title}`}
      />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:creator" content={twitter || siteMetadata.author} />
      <meta
        name="twitter:image"
        content={
          props.image
            ? `${siteUrl}/${removeBackSlashStart(props.image)}`
            : standardImage
        }
      />
      {readingTime && <meta name="twitter:label1" content="Reading time" />}
      {readingTime && <meta name="twitter:data1" content={readingTime} />}
      {languages.map(lng => (
        <link
          rel="alternate"
          key={lng}
          href={createUrlWithLang(lng)}
          hrefLang={lng}
        />
      ))}
      {/* adding a fallback page for unmatched languages */}
      {published && <meta name="article:published_time" content={published} />}
      {updated && <meta name="article:modified_time" content={updated} />}
      {category && <meta name="article:section" content={category} />}
      {tags && <meta name="article:tag" content={tags} />}
      {language === `da` && (
        <meta
          property="og:title"
          content={
            props.title
              ? `${author} | ${title}`
              : `${author} | ${siteMetadata.title_da}`
          }
        />
      )}
      {language === `en` && (
        <meta
          property="og:title"
          content={
            props.title
              ? `${author} | ${title}`
              : `${author} | ${siteMetadata.title_en}`
          }
        />
      )}
      <meta
        property="og:type"
        content={contentType ? contentType : "website"}
      />
      <meta property="og:url" content={canonicalUrl} />
      <meta property="og:author" content={author} />
      <meta
        property="og:image"
        content={
          props.image
            ? `${NoSecuresiteUrl}/${removeBackSlashStart(props.image)}`
            : standardImage
        }
      />
      <meta property="og:image:type" content={imageType} />
      <meta
        property="og:image:secure_url"
        content={
          props.image
            ? `${siteUrl}/${removeBackSlashStart(props.image)}`
            : standardImage
        }
      />

      <meta property="og:image:width" content={imageWidth} />
      <meta property="og:image:height" content={imageHeight} />
      <meta property="og:description" content={description} />
      {language === `da` && (
        <meta property="og:site_name" content={`${siteMetadata.title_da}`} />
      )}
      {language === `en` && (
        <meta property="og:site_name" content={`${siteMetadata.title_en}`} />
      )}
      {language === `da` && (
        <meta
          itemProp="name"
          content={
            props.title
              ? `${author} | ${title}`
              : `${author} | ${siteMetadata.title_da}`
          }
        />
      )}
      {language === `en` && (
        <meta
          itemProp="name"
          content={
            props.title
              ? `${author} | ${title}`
              : `${author} | ${siteMetadata.title_en}`
          }
        />
      )}
      <meta itemProp="description" content={description} />
      <meta
        itemProp="image"
        content={props.image ? `${siteUrl}/${props.image}` : standardImage}
      />

      <script type="application/ld+json">
        {`
        {
          "@context": "https://schema.org",
          "@type": "WebPage",
          "mainEntityOfPage": {
          "@type": "${contentType}",
          "@id": "${canonicalUrl}"
        },
          "headline": "${title}",
          "image": "${
            props.image
              ? `${siteUrl}/${removeBackSlashStart(props.image)}`
              : standardImage
          }",
          "datePublished": "${published}",
          "dateModified": "${updated}",
          "author": {
            "@type": "Person",
            "name": "${author ? author : `${siteMetadata.author}`}"
          },
          "publisher": {
            "@type": "Person",
            "name": "${siteMetadata.author}",
            "logo": {
              "@type": "ImageObject",
              "url": "${siteUrl}/icons/icon-512x512.png?v=4db9887fdc702424d8fa53e12b3563e0"},
              "description": "${description
                .replace(/"/g, '\\"')
                .replace(/(\r\n|\n|\r)/gm, "")}"
            }
          }, 
        }
        `}
      </script>
    </Helmet>
  )
}

export default Seo
