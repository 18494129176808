import React, { useEffect, useState, useContext } from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import useSound from "use-sound"
import ToggleSound from "../sounds/toggle-button.mp3"
import { GlobalContext } from "../components/soundToggle.js"

const ThemeSwitch = () => {
  const root =
    typeof window !== `undefined` ? window.document.documentElement : undefined
  const useSoundEnabled = useContext(GlobalContext)
  const soundEnabled = useSoundEnabled.soundEnabledStatus
  const [isChecked, setIsChecked] = useState("false")
  const [play] = useSound(ToggleSound, {
    volume: 0.3,
    soundEnabled: soundEnabled,
  })

  useEffect(() => {
    let initialColorValue =
      typeof window !== `undefined`
        ? root.getAttribute("data-dark-mode")
        : "false"
    const themeSwitch = document.getElementById("themeSwitch")
    let body = document.querySelector("body")
    themeSwitch.checked = initialColorValue
    if (initialColorValue === "true") {
      body.classList.add("dark")
    } else {
      body.classList.add("light")
    }
    setIsChecked(initialColorValue)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const { t } = useTranslation()

  const toggleTheme = () => {
    play()
    let body = document.querySelector("body")
    if (body.classList.contains("dark")) {
      setIsChecked("false")
      body.classList.remove("dark")
      body.classList.add("light")
      localStorage.setItem("color-mode", "light")
      root.style.setProperty("--initial-color-mode", "light")
      root.setAttribute("data-dark-mode", false)
    } else {
      setIsChecked("true")
      body.classList.remove("light")
      body.classList.add("dark")
      localStorage.setItem("color-mode", "dark")
      root.style.setProperty("--initial-color-mode", "dark")
      root.setAttribute("data-dark-mode", true)
    }
  }

  useEffect(() => {
    //click button when pressing enter
    const handleEnterKey = e => {
      document.querySelector(".themeToggle").click()
    }
    const keyListenersMap = new Map([[13, handleEnterKey]])
    function keyListener(e) {
      // get the listener corresponding to the pressed key
      const listener = keyListenersMap.get(e.keyCode)
      // call the listener if it exists
      return listener && listener(e)
    }
    document
      .querySelector(".themeToggle")
      .addEventListener("keydown", keyListener)
  }, [])

  return (
    <>
      <div
        id="themeSwitch"
        className="themeSwitch"
        aria-label={t("THEME_ARIA_LABEL")}
        title={t("THEME_ARIA_LABEL")}
      >
        <input
          tabindex="0"
          aria-labelledby="themeSwitch"
          aria-checked={isChecked}
          className="themeToggle"
          type="checkbox"
          onClick={toggleTheme}
        />
        <span></span>
      </div>
    </>
  )
}

export default ThemeSwitch
