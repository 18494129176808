import React, { useContext } from "react"
import { globalHistory as history } from "@reach/router"
import { Link } from "gatsby"
import { useTranslation, useI18next } from "gatsby-plugin-react-i18next"
import useSound from "use-sound"
import LanguageToggle from "../sounds/language-toggle.mp3"
import { GlobalContext } from "../components/soundToggle.js"

const LanguageSwitch = () => {
  const { language } = useI18next()
  const { t } = useTranslation()
  const useSoundEnabled = useContext(GlobalContext)
  const soundEnabled = useSoundEnabled.soundEnabledStatus
  const [play] = useSound(LanguageToggle, {
    volume: 0.25,
    soundEnabled: soundEnabled,
  })
  const { location } = history
  let currentPath = location.pathname
  currentPath = currentPath.endsWith("/") ? currentPath : `${currentPath}/`

  let englishPagePath
  let danskPath
  if (currentPath.includes(`/en/`)) {
    englishPagePath = currentPath
    danskPath = currentPath.split(`/${language}`)[1]
  } else {
    englishPagePath = `/en${currentPath}`
    danskPath = currentPath
  }

  return (
    <nav className="languageSwitch" aria-label={t("LANGUAGE_ARIA_LABEL")}>
      <Link
        onClick={play}
        to={danskPath}
        activeClassName="active"
        title="Dansk"
      >
        DA
      </Link>
      <p style={{ fontSize: "21px" }}>|</p>
      <Link
        onClick={play}
        to={englishPagePath}
        activeClassName="active"
        title="English"
      >
        EN
      </Link>
    </nav>
  )
}

export default LanguageSwitch
